//a react component for the hero section that displays the hero image centered on the page

//
import React from 'react';
import './hero.css';

function Hero() {
  return (
    <div className='hero'>
      <div className='outer-container'>
        <div className='inner-container'>
          <span className='text title'>Immersive View </span>
          <span className='text'>
            Provides As-Built Construction Documentation Photography which is
            like an X-Ray view into the walls of the home
          </span>
        </div>
      </div>
    </div>
  );
}
//
export default Hero;
