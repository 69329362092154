// react component for navbar that shows the logo on the left side and nothing else
//
import React from 'react';
import logo from '../../logo.png';
import './navbar.css';

function Navbar() {
  return (
    <div className='navbar'>
      <img src={logo} className='navbar-logo' alt='logo' />
    </div>
  );
}
//
export default Navbar;
