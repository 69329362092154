import React, { useEffect } from 'react';

function Demo1() {
  useEffect(() => {
    // Load and return the script once it's fully loaded
    function loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    }

    async function loadScriptsAndInit() {
      // Check if scripts are loaded globally, else load them dynamically.
      if (typeof pano2vrPlayer === 'undefined') {
        await loadScript(
          'https://s3-us-west-1.amazonaws.com/creativequickfix.com/Projects/205topaz/pano2vr_player.js'
        );
        await loadScript(
          'https://s3-us-west-1.amazonaws.com/creativequickfix.com/Projects/205topaz/skin.js'
        );
      }
      initPanorama();
    }

    loadScriptsAndInit();
  }, []);

  function initPanorama() {
    // eslint-disable-next-line no-undef
    const pano = new pano2vrPlayer('container');
    // eslint-disable-next-line no-undef
    const skin = new pano2vrSkin(pano);
    pano.readConfigUrlAsync(
      'https://s3-us-west-1.amazonaws.com/creativequickfix.com/Projects/205topaz/pano.xml'
    );
  }

  return (
    <div
      id='container'
      style={{
        width: '1000px',
        height: '1000px',
        overflow: 'hidden',
        margin: 'auto',
      }}
    >
      Loading...
    </div>
  );
}

export default Demo1;
