import './App.css';
import Demo1 from './components/demo1/demo1';
import Hero from './components/hero/hero';
import Navbar from './components/navbar/navbar';

function App() {
  return (
    <div className='App'>
      <Navbar />
      <Hero />
      <Demo1 />
    </div>
  );
}

export default App;
